import React from 'react'
import { navigate } from 'gatsby'
import { Button } from 'semantic-ui-react'
// Images
import trash from '../../svg/TrashCanIcon.svg'
// Styles
import {
  DeleteIcon,
  IconWrapper,
  RightIconWrapper,
  Title,
  Wrapper,
} from './PageHeader.styles'

const PageHeader = ({
  children,
  exitRoute,
  onClickDelete = null,
  onClickClose = null,
  state = {},
}) => {
  function exit() {
    if (exitRoute)
      navigate(exitRoute, {
        state,
      })
    else navigate(-1)
  }

  return (
    <Wrapper>
      {/*<IconWrapper>
        <Button
          basic
          circular
          data-qa="goBack"
          icon="x"
          onClick={onClickClose ? onClickClose : exit}
          size="small"
        />
      </IconWrapper>*/}
      <Title data-qa="pageHeaderTitle">{children}</Title>
      {onClickDelete ? (
        <RightIconWrapper onClick={onClickDelete}>
          <DeleteIcon src={trash} />
        </RightIconWrapper>
      ) : null}
    </Wrapper>
  )
}

export default PageHeader
